<template>
  <div id="ExportExcelWord">
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <b-button class="mt-3" variant="dark" @click="add()"
      ><b-icon-download /> Exportar datos</b-button
    >

    <div>
      <b-modal scrollable centered v-model="show" title="Exportar">
        <b-container fluid>
          <!--Contenido modal-->
          <AlertError ref="error" />
          <b-row>
            <b-col lg="6" sm="12">
              <div>
                <label for="exampleInputPassword1"
                  ><b>Fecha de inicio:</b></label
                >
                <b-form-input
                  type="date"
                  class="form-control"
                  v-model="dataExport.startDate"
                  :state="dataExport.startDate != null"
                  required
                />
              </div>
            </b-col>
            <b-col lg="6" sm="12">
              <div>
                <label for="exampleInputPassword1"><b>Fecha de fin:</b></label>
                <b-form-input
                  type="date"
                  class="form-control"
                  v-model="dataExport.endDate"
                  :state="dataExport.endDate != null"
                  required
                />
              </div>
            </b-col>
          </b-row>
          <div class="my-2">
            <label for="exampleInputPassword1"><b>Banco:</b></label>
            <b-form-select
              v-model="dataExport.bank"
              :options="banks"
              :state="dataExport.bank != null"
              required
            />
          </div>
        </b-container>
        <!--Footer modal-->
        <template #modal-footer>
          <div class="col">
            <b-button class="w-100" variant="success" @click="getDataExcel()"
              ><b-icon-file-earmark-excel /> Exportar Excel</b-button
            >
          </div>
          <div class="col">
            <b-button class="w-100" variant="warning" @click="getDataPdf()"
              ><b-icon-file-richtext /> Exportar Pdf</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import jspdf from "jspdf";

import * as XLSX from "xlsx/xlsx.mjs";

/* load 'fs' for readFile and writeFile support */
import * as fs from "fs";
XLSX.set_fs(fs);

/* load 'stream' for stream support */
import { Readable } from "stream";
XLSX.stream.set_readable(Readable);

/* load the codepage support library for extended support with older formats  */
import * as cpexcel from "xlsx/dist/cpexcel.full.mjs";
XLSX.set_cptable(cpexcel);

import AlertError from "./AlertError.vue";

export default {
  components: {
    AlertError,
  },
  name: "ExportExcelWord",
  data() {
    return {
      selected: "",
      show: false,
      dataExport: {},
      dataList: [],
      banks: [
        { text: "BCP", value: "BCP" },
        { text: "Banco de la nación", value: "Banco de la nación" },
      ],
    };
  },
  methods: {
    async getDataExcel() {
      if (
        this.dataExport.startDate == null ||
        this.dataExport.endDate == null ||
        this.dataExport.bank == null
      ) {
        this.$refs.error.showAlert("Ingrese todos los datos");
        return;
      }
      //post solo permite trabajar en las respuestas
      this.selected = "circular";
      this.show = false;
      await this.$axios
        .get(
          "/report/enroll-table?startDate=" +
            this.dataExport.startDate +
            "&endDate=" +
            this.dataExport.endDate +
            "&bank=" +
            this.dataExport.bank,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.dataList = res.data;
          this.exportExcel(
            this.dataExport.bank +
              "_" +
              this.dataExport.startDate +
              "_" +
              this.dataExport.endDate
          );
          this.show = false;
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
          this.$message.error("Error al generar documento");
          //Token vencido
          if (error.response.data.error === "Unauthenticated") {
            this.$store.commit("logout");
            alert("Sesión expirada!");
            window.location.href = window.location.origin;
          }
          this.$message.error(error.response.data.content);
        });
      this.selected = "";
    },
    async getDataPdf() {
      if (
        this.dataExport.startDate == null ||
        this.dataExport.endDate == null ||
        this.dataExport.bank == null
      ) {
        this.$refs.error.showAlert("Ingrese todos los datos");
        return;
      }
      //post solo permite trabajar en las respuestas
      this.selected = "circular";
      this.show = false;
      await this.$axios
        .get(
          "/report/enroll-pdf?startDate=" +
            this.dataExport.startDate +
            "&endDate=" +
            this.dataExport.endDate +
            "&bank=" +
            this.dataExport.bank,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          var aux = [];
          for (var i in res.data) {
            var obj = res.data[i];
            aux.push(obj);
          }
          this.dataList = aux;
          this.exportPdf(
            this.dataList,
            this.dataList.length,
            this.dataExport.bank +
              "_" +
              this.dataExport.startDate +
              "_" +
              this.dataExport.endDate
          );
          this.show = false;
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
          //Token vencido
          if (error.response.data.error === "Unauthenticated") {
            this.$store.commit("logout");
            alert("Sesión expirada!");
            window.location.href = window.location.origin;
          }
          this.$message.error(error.response.data.content);
        });
      this.selected = "";
    },
    exportExcel: function (nombre) {
      //Quitar imagen
      for (let index = 0; index < this.dataList.length; index++) {
        delete this.dataList[index].image;
      }
      //
      //let data = XLSX.utils.json_to_sheet(this.dataList);
      let data = XLSX.utils.json_to_sheet(this.dataList, {
        header: [
          "index",
          "lastname",
          "name",
          "dni",
          "quantity",
          "type",
          "amount",
          "operationNumber",
          "depositDate",
          "program",
          "turn",
        ],
      });
      data["A1"].v = "NRO.";
      data["B1"].v = "APELLIDOS";
      data["C1"].v = "NOMBRES";
      data["D1"].v = "DNI";
      data["E1"].v = "CANTIDAD";
      data["F1"].v = "TIPO";
      data["G1"].v = "MONTO";
      data["H1"].v = "NRO. OPERACIÓN";
      data["I1"].v = "FECHA DE DEPÓSITO";
      data["J1"].v = "PROGRAMA";
      data["K1"].v = "TURNO";
      const workbook = XLSX.utils.book_new();
      const filename = "devschile-admins";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, nombre + "_ReporteExcel.xlsx");
    },

    async exportPdf(info, tamOri, nombre) {
      const doc = new jspdf();
      var arr = [
        { x: 110, y: 20 },
        { x: 110, y: 155 },
      ];
      var arrText = [
        [
          { x: 15, y: 25 },
          { x: 15, y: 30 },
          { x: 15, y: 35 },
          { x: 15, y: 45 },
          { x: 15, y: 50 },
          { x: 15, y: 60 },
          { x: 15, y: 65 },
          { x: 15, y: 70 },
          { x: 15, y: 80 },
          { x: 15, y: 85 },
          { x: 15, y: 90 },
          { x: 15, y: 95 },
          { x: 15, y: 100 },
          { x: 15, y: 120 },
          { x: 15, y: 130 },
        ],
        [
          { x: 15, y: 160 },
          { x: 15, y: 165 },
          { x: 15, y: 170 },
          { x: 15, y: 180 },
          { x: 15, y: 185 },
          { x: 15, y: 195 },
          { x: 15, y: 200 },
          { x: 15, y: 205 },
          { x: 15, y: 215 },
          { x: 15, y: 220 },
          { x: 15, y: 225 },
          { x: 15, y: 230 },
          { x: 15, y: 235 },
          { x: 15, y: 255 },
          { x: 15, y: 265 },
        ],
      ];
      //var tamOri = 5;
      var tam = Math.ceil(tamOri / 2);
      var cont = 0;
      for (let indexG = 0; indexG < tam; indexG++) {
        console.log("entro Arr G");
        if (indexG > 0) {
          doc.addPage();
        }
        //Margenes
        doc.line(10, 14, 200, 14);
        doc.line(10, 149, 200, 149);
        doc.line(10, 284, 200, 284);
        doc.line(10, 14, 10, 284);
        doc.line(105, 14, 105, 284);
        doc.line(200, 14, 200, 284);
        //Tamaño letra
        doc.setFontSize(9);
        //
        for (let index = 0; index < 2; index++) {
          //console.log("entro Arr P " + cont + " : " + tamOri);
          doc.text(
            arrText[index][0].x,
            arrText[index][0].y,
            info[cont].program
          );
          doc.text(arrText[index][1].x, arrText[index][1].y, info[cont].turn);
          doc.text(
            arrText[index][2].x,
            arrText[index][2].y,
            "01 " + info[cont].type
          );
          doc.text(
            arrText[index][3].x,
            arrText[index][3].y,
            info[cont].lastname
          );
          doc.text(arrText[index][4].x, arrText[index][4].y, info[cont].name);
          doc.text(arrText[index][5].x, arrText[index][5].y, info[cont].dni);
          doc.text(arrText[index][6].x, arrText[index][6].y, info[cont].phone);
          doc.text(arrText[index][7].x, arrText[index][7].y, info[cont].email);
          doc.text(arrText[index][8].x, arrText[index][8].y, "AREQUIPA");
          doc.text(
            arrText[index][9].x,
            arrText[index][9].y,
            "BANCO: " + info[cont].bank
          );
          doc.text(
            arrText[index][10].x,
            arrText[index][10].y,
            "OP. " + info[cont].operationNumber
          );
          doc.text(
            arrText[index][11].x,
            arrText[index][11].y,
            "MONTO: S/. " + info[cont].amount
          );
          doc.text(
            arrText[index][12].x,
            arrText[index][12].y,
            "FECHA: " + info[cont].depositDate
          );
          doc.text(arrText[index][13].x, arrText[index][13].y, "BOLETA");
          doc.text(
            arrText[index][14].x,
            arrText[index][14].y,
            info[cont].index + ""
          );
          doc.addImage(
            "https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/" +
              info[cont].image +
              "?alt=media",
            arr[index].x,
            arr[index].y,
            85,
            123
          );
          cont++;
          if (cont == tamOri) index = 2;
        }
        if (cont == tamOri) indexG = tam;
      }
      //console.log("llego");
      doc.save(nombre + "_ReportePdf.pdf");
    },
    exit() {
      this.show = false;
    },
    add() {
      this.dataExport = {
        startDate: null,
        endDate: null,
        bank: null,
      };
      this.show = true;
    },
  },
};
</script>
