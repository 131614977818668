<template>
  <div id="dashboard">
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h1><b-icon icon="file-spreadsheet" /> Movimientos Financieros</h1>
        </div>
        <div class="card-body">
          <div class="container col-12">
            <div>
              <b-row class="mb-3">
                <b-col lg="5" sm="12">
                  <div>
                    <label for="exampleInputPassword1"
                      ><b>Fecha de inicio:</b></label
                    >
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Ingrese fecha nacimiento"
                      v-model="data.startDate"
                      required
                    />
                  </div>
                </b-col>
                <b-col lg="5" sm="12">
                  <div>
                    <label for="exampleInputPassword1"
                      ><b>Fecha de fin:</b></label
                    >
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Ingrese fecha nacimiento"
                      v-model="data.endDate"
                      required
                    />
                  </div>
                </b-col>
                <b-col lg="2" sm="12">
                  <button
                    type="button"
                    @click="search()"
                    style="
                      background-color: #1d425e;
                      border: none;
                      color: white;
                      margin-top: 30px;
                    "
                    class="btn form-control"
                  >
                    Filtrar
                  </button>
                </b-col>
              </b-row>
              <div v-if="desserts != ''">
                <div v-if="desserts.list != ''">
                  <b-table
                    striped
                    responsive
                    stacked="lg"
                    :items="desserts.list"
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                  >
                    <template #cell(date)="row">
                      <p v-text="fecha(row.item.date)" />
                    </template>
                    <template #cell(amount)="row">
                      <b class="text-success"
                        ><b-icon :icon="iconos(row.item.name)" />
                        {{ row.item.symbol }}{{ row.item.amount }}</b
                      >
                    </template>
                    <template #cell(enlace)="row">
                      <a
                        v-if="row.item.image != null"
                        :href="row.item.image + '?alt=media'"
                        target="_blank"
                        ><b-icon type="button" icon="link45deg"
                      /></a>
                      <em v-else>Vacio</em>
                    </template>
                  </b-table>
                  <b-pagination
                    style="display: flex; justify-content: center"
                    v-model="currentPage"
                    pills
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                  ></b-pagination>
                  <!--Montos totales-->
                  <div class="card">
                    <div class="card-header">
                      <h4><b-icon icon="cash-stack" /> Montos totales</h4>
                    </div>
                    <div class="card-body">
                      <b-table :items="dataTotal" :fields="fieldsTotal">
                        <template #cell(income)="row">
                          <b class="text-success"
                            ><b-icon-chevron-double-down />
                            {{ row.item.income }}</b
                          >
                        </template>
                        <template #cell(egress)="row">
                          <b class="text-danger"
                            ><b-icon-chevron-double-up />
                            {{ row.item.egress }}</b
                          >
                        </template>
                        <template #cell(difference)="row">
                          <b
                            class="text-success"
                            v-if="row.item.difference >= 0"
                          >
                            <b-icon-cash-stack /> {{ row.item.difference }}</b
                          >
                          <b class="text-danger" v-else>
                            <b-icon-cash-stack /> {{ row.item.difference }}</b
                          >
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
                <div class="text-center" v-else>
                  <em
                    >No hay movimientos financieros en este rango de fechas</em
                  >
                </div>
                <div class="text-center">
                  <ExportExcelWord />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExportExcelWord from "../components/ExportExcelWord.vue";
export default {
  components: { ExportExcelWord },
  data: () => ({
    selected: "",
    perPage: 5,
    currentPage: 1,
    desserts: [],
    data: {
      startDate: "",
      endDate: "",
    },
    dataTotal: [],
    fields: [
      { key: "name", label: "Nombre" },
      { key: "registerType", label: "Tipo" },
      { key: "date", label: "Fecha y hora" },
      { key: "amount", label: "Cantidad" },
      { key: "enlace", label: "Enlace" },
    ],
    fieldsTotal: [
      { key: "income", label: "Ingreso Total" },
      { key: "egress", label: "Egreso Total" },
      { key: "difference", label: "Monto Total" },
    ],
  }),
  computed: {
    rows() {
      if (this.desserts.length != 0) return this.desserts.list.length;
    },
  },
  created() {
    this.searchIni();
  },
  methods: {
    fecha(date) {
      return date.substring(0, 10) + " (" + date.substring(11, 16) + ")";
    },
    iconos(data) {
      if (data == "Ingresos en soles") return "chevron-double-down";
      else return "chevron-double-up";
    },
    async searchIni() {
      this.selected = "circular";
      await this.$axios
        .get("/financial-movement", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
          var aux = [];
          var obj = {
            income: res.data.income,
            egress: res.data.egress,
            difference: res.data.difference,
          };
          aux.push(obj);
          this.dataTotal = aux;
          //console.log(this.desserts);
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async search() {
      if (this.data.startDate == "" || this.data.endDate == "") {
        this.$message.error("Llene los campos");
        return;
      }
      this.selected = "circular";
      await this.$axios
        .post("/financial-movement", this.data, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
          var aux = [];
          var obj = {
            income: res.data.income,
            egress: res.data.egress,
            difference: res.data.difference,
          };
          aux.push(obj);
          this.dataTotal = aux;
          console.log(this.desserts);
          this.selected = "";
        })
        .catch((error) => {
          this.selected = "";
          this.$message.error(error.response.data.content);
          console.log(error);
        });
    },
  },
};
</script>

<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
</style>